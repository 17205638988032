/* integrations */

.integTabsNew {
  margin-bottom: 20px;
  color: #5b5b5c !important;
}
.integTabsNew > li > button {
  color: #5b5b5c !important;
}

.commonDemoForm {
  flex-direction: row;
  display: flex;
  margin-top: 20px;
}
.headerAboutSide {
  background-color: #eefeff;
  /* height: 400px; */
  width: 100%;
  /* padding: 20px; */
  padding-top: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.headerBorder {
  position: absolute;
  height: 80%;
  margin-left: -25px;
  border-left: 1px solid #e2e2e2;
}
.aboutSideDropdown {
  /* height: 400px; */
  /* width: 0%; */
  /* padding: 0; */
  /* position: relative; */
  /* margin:0; */
  /* margin-top:-10px !important; */
  padding: 0;
  /* left: -24.5em !important; */
  z-index: 10000;
  /* margin-top: -10px !important; */
  border: none;
  border-radius: 15px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.aboutSideDropdown > div {
  width: 100%;
}
.aboutUsIllustration {
}
/* clients neew */
.clientsNew {
  display: flex;
  justify-content: center;
  margin-block: 30px;
  cursor: pointer;
  filter: grayscale(100%);
}
.clientsNewSpacing {
  margin-right: 50px;
  width: 13%;
}

/* login */
.loginGoogleRow {
  display: flex;
  justify-content: center;
}
.googleLoginModal {
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  margin-top: 15px;
  margin-inline: 10px;
  border-radius: 50px;
  width: 100px;
  height: 55px;
  border: 1.5px solid #ced4da;
}

.googleLoginModal:hover {
  box-shadow: 0 15px 26px rgba(0, 0, 0, 0.1);
}
/* collapse  */
.collapseNew {
  padding: 20px;
  border-radius: 25px;
}

/* Testimonial  */

.TestimonialTab {
  cursor: pointer;
  width: 450px;
  margin-inline: 30px;
  justify-content: center;
  text-align: center;
}

.TestimonialTab > p {
  margin-block: 30px;
}
.TestimonialTab > h4 {
  font-weight: bold;
}
/* quick module  */
.QuickModule {
  justify-content: center;
  display: flex;
  margin-block: 30px;
  padding: 10x;
  border-radius: 50px;
  background-color: #001233;
}
.learnButton {
  color: white;
  padding: 20px;
  border-radius: 25px;
  background: linear-gradient(90deg, #dcf7ff 0%, rgba(220, 247, 255, 0.14) 0.01%, rgba(47, 140, 255, 0) 100%);
  text-decoration: none;
}
.learnButton:hover {
  color: white;
  box-shadow: 0px 4px 66px rgba(15, 15, 15, 0.35);
}
.modulesInteraction > p {
  margin-top: 15px;
}
.leftQucikModule {
  margin-top: 20px;
  margin-left: 50px;
}
.modulesInteraction {
  align-items: center;
  padding-inline: 30px;
  border-radius: 50px;
  cursor: pointer;
  margin: 8px;
}
.modulesInteraction:hover {
  background-color: #012251;
}

.quicModuleTitle {
  color: white;
  margin: 0;
  font-size: 45px;
  letter-spacing: 0.3px;
  font-family: EnzoLight;
  font-weight: bold;
}
.quickModuleBg {
  flex: 1;
  color: white;
  transition: background-color 250ms;
  padding-block: 30px;
  background-color: #005fcf;
}
.quickCheckMarks {
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 15px;
  width: '100%';
  background: linear-gradient(90deg, #dcf7ff 0%, rgba(220, 247, 255, 0.14) 0.01%, rgba(47, 140, 255, 0) 100%);
  display: flex;
  flex-direction: row;
}
.quickModuleGradient {
  margin: 0px;
  padding: 0px;
  font-size: 13px;
  font-weight: bold;
  background: linear-gradient(to right, #c9ffbf, #ffafbd);
  width: 80%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* GDPR  */
.gdHeading {
  margin-block: 15px;
}
.gdParagraph {
  color: #848484;
}

/* modules new home  */
.newModuleStyles {
  text-align: center;
}

.newModuleStyles > p {
  color: #898989;
}
.newModuleHover {
  padding-block: 60px !important;
  border-radius: 50px;
  cursor: pointer;
}
.newModuleHover:hover {
  background-color: #f9fafb !important;
}
/* New badge  */

.newBadgeSection {
  text-align: center;
}

/* Home Inegration  */
.homeIntegrationTitle {
  font-family: EnzoLight;
  padding-top: 100px;
}
.accordion-button:not(.collapsed) {
  background-color: black !important;
}

.homeIntegrationTitle > h2 {
  font-size: 40px;
  margin-top: 0px;
  padding-top: 0px;
  font-weight: 600;
  font-family: EnzoBold;

  color: #2708a0;
}
.IntegrationImage {
  display: flex;
  justify-content: center;
}
/* Home Mobile View  */
.homeMobileContainer {
  background-color: #f8f9fa;
}
.homeMobileView {
  padding-bottom: 50px;
  text-align: center;
}
.homeMobileTile {
  text-align: center;
  padding-top: 50px;
}
.homeMobileTile > h1 {
  font-size: 45px;
  text-align: center;
  font-family: EnzoLight;
  font-weight: bolder;
  background: linear-gradient(to right, #fc466b, #3f5efb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* white button   */
.commonDemoEmail {
  border-radius: 50px;
  height: 40px;
  border: none;
  padding-left: 20px;
  font-size: 14px;
  width: 300px;
}
.whiteButton {
  border-radius: 25px;
  padding-inline: 20px;
  padding-block: 12px;
  cursor: pointer;
  transition: 0.3s;
  text-align: center;
  color: #fff;
  border: 3px solid #fff;
  margin-right: 20px;
}
.blueButton {
  border-radius: 50px;
  padding-inline: 30px;
  padding-block: 10px;
  cursor: pointer;
  transition: 0.3s;
  text-align: center;
  color: #005fcf;
  border: 2px solid #005fcf;
  margin-right: 20px;
}
.purpleButton {
  border-radius: 50px;
  padding-inline: 35px;
  padding-block: 15px;
  cursor: pointer;
  transition: 0.3s;
  text-align: center;
  color: white;
  background: linear-gradient(117.69deg, #231fff -29.66%, #bf41eb 113.48%);
  box-shadow: 0px 4px 66px rgba(226, 180, 255, 0.55);
  margin-right: 20px;
}
.purpleButton:hover {
  box-shadow: 0px 4px 66px rgba(226, 180, 255, 0.853);
}
.blueButton:hover {
  background-color: #e6f0fb;
}
.whiteButton:hover {
  box-shadow: 0px 4px 66px rgba(226, 180, 255, 0.45);
}

/* NEwBANNER  */
.NewBannerBG {
  background: url('https://cdn.webhr.co/Website/images/landing_page/banner_background.webp');
  background-repeat: no-repeat;
  justify-content: center;
  background-size: cover;
  flex: 1;
  display: flex;
}
.responsiveBannerImage {
  width: 120%;
}
.bannerLeftCol {
  align-items: center !important;
  justify-content: center !important;
}
.rankedSection {
  align-items: center;
  background-color: #f8f9fa;
  /* background-image: linear-gradient(to right, #ece9e6, #ffffff); */
  padding-block: 20px;
}
.rankedRow {
  color: #6c757d;
  align-items: center;
}
.rankedRow > h5 {
  font-family: EnzoBold;
  font-weight: bold;
  margin: 0;
  color: #2708a0;
}
.rankedRow > p {
  color: #6c757d;
  margin: 0 !important;
  padding: 0 !important;
}
/* Header  */
.headerMobileHover:hover {
  /* transform: scale(1.3); */
  overflow: hidden !important;
}
.navbarModules:hover {
  background-color: #f8f9fa;

  cursor: pointer;
}
.headerMobileHover {
  width: 100%;
  margin-top: 90px;
  overflow: hidden !important;
  transition: transform 0.2s ease;
}
.navbarModules {
  flex-direction: row;
  display: flex;
  padding-top: 30px;
  border-radius: 25px;
  padding-inline: 20px;
  color: #121212;
}
.navbarModules > div > p {
  color: #6c757d;
  font-size: 13px;
}
.NewHeaderheader {
  position: sticky;
  top: 0;
  width: 100%;
  /* padding-inline: 120px; */
  background-color: white;
  z-index: 1000;
  box-shadow: 0 15px 26px rgba(0, 0, 0, 0.1);
}

.navLinks {
  display: block;
  text-decoration: none;
  color: #6c757d;
  margin-inline: 5px;
}
.navLinks:hover {
  color: #21558d;
}
.dropdownNavLinks {
  color: #7f868f !important;
}
.dropdownNavLinks:hover {
  color: #21558d;

  background-color: #f8f9fa;
}
.navLinks > a :hover {
  color: #21558d;
}
.navLinks > a :active {
  color: #21558d;
}

.dropdownHover:hover {
  background-color: #f8f9fa;
}
.headerDropdown {
  border: none !important;
  background: none !important;
  overflow: hidden;
  overflow-y: scroll;
  /* width: 100% !important; */
  /* margin-top: 1em !important; */
  height: 30em;
  padding: 10px;
  /* width: 70em; */
  /* left: -175% !important; */
}

.mobileHeader {
  background: linear-gradient(90deg, #000428 0%, #004e92 100%);
  width: 110%;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  height: 403px;
}

.headerDropdown > div {
  background-color: white !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  /* box-shadow: 0 15px 26px rgba(0, 0, 0, 0.1); */
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  /* margin-top: 15px; */
}
.productDropdown > div {
  border: none !important;
  box-shadow: 0 15px 26px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.headerDropdown:hover {
  color: #21558d;
}
.headerDropdown > a:hover {
  color: #21558d;
}
.headerDropdown > a:active {
  color: #21558d;
}
/* Contact  */
.contactLiveDiv {
  padding: 60px;
}
.contactChat {
  font-weight: 600;
  font-size: 30px;
}
.contactDesc {
  color: #0a4f29;
}
.contactIllustration {
  display: flex;
  background: url('https://res.cloudinary.com/dpugxh9rw/image/upload/v1683716712/Union_qkuc0t.png');
  background-repeat: no-repeat;
  margin-top: 50px;
  padding-bottom: 130px;
}
.contactImage {
  float: right;
  padding-block: 20px;
}

/* layout heading */

.layoutHeading {
  padding-bottom: 3em;
}

/* Header dropdwon  */

.dropdownHoverStyle {
  background-color: white;
}

.dropdownHoverStyle:hover {
  color: #005fcf !important;
}

/* CONTENT */
.dyanmicCol {
  align-items: center;
}
.dynamicBanner {
  height: 400;
  width: 100%;
  float: right;
  align-items: center;

  display: flex;
  justify-content: flex-end;
}
.contentHeading {
  font-size: 60px;
  color: #21252a;
}
.contentTime {
  margin-right: 15px;
  font-weight: bold;
  color: #484c50;
}
.contentShortDesc {
  margin-top: 10px;
  margin-bottom: 20px;
}
.contentSearch {
  margin-block: 10px;
  align-items: center;
}
.contentDesc {
  font-weight: bold;
  color: #898989;
}
.contentBreadcrumb {
  margin-top: 40px;
  cursor: pointer;
}
.breadcrumbLink > a {
  color: #0d64ae;
  text-decoration: none;
}
.searchable {
  margin-block: 35px;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
}
.searchable :active {
}
.searchable > div {
  border: none;
  padding: 5px;

  color: #939ba3;
}

/* glossary */

.glossaryBG {
  display: flex;
  /* background: url('https://cdn.webhr.co/Website/images/landing_page/content-background.webp'); */
  background-repeat: no-repeat;
  margin-top: -110px;
  align-items: center;
  padding-top: 150px;
  background-size: contain;
}
.lastContent {
  margin-top: 15vh;
}
/* about */
.attendanceLast {
  text-align: center;
}
.aboutWebHRIcons {
  margin-right: 20px;
}
.attendanceLast > h1 {
  font-size: 50px;
  font-family: EnzoBold;
}
.attendanceLast > p {
  color: #898989;
}
.moduleSecHeading {
  font-size: 33px;
  font-family: EnzoBold;
}
.moduleQuoteBG {
  background: url('https://cdn.webhr.co/Website/images/webp_imges/Review_qoutebg_01.webp');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-items: center;
}
.CoreHRModuleBG {
  background: url('https://cdn.webhr.co/Website/images/webp_imges/Review_qoutebg_00.webp');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-items: center;
}
.reviewImages {
  margin-bottom: 20px;
}
.moduleReview {
  padding: 100px;
  text-align: center;
}
.moduleReview > h5 {
  margin-top: 20px;
  font-family: EnzoBold;
  color: #7ca96c;
  font-weight: bold;
}
.moduleReviewText {
  font-size: 13px;
  margin: 0px;
}
.moduleUnderline::after {
  content: '';
  margin: 0px;
  padding: 0px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #d1ffec 100%);
  height: 35px;
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 3%;
  z-index: -1;
}
.moduleUnderline {
  font-family: EnzoBold;
  position: relative;
  width: fit-content;
}
.moduleBlueSec {
  font-family: EnzoBold;
  color: #005fcf;
}

.moduleSecContent {
  margin-top: 15px;
  color: #898989;
  font-family: AzoSans;
}

.contactFormSpacing {
  margin-bottom: 15px;
}
.contactForm {
  background-color: white;
  padding: 30px;
  border-radius: 15px;
}
.aboutImg {
  width: 100%;
}
.layoutBackground {
  background-image: url('https://cdn.webhr.co/Website/images/webp_imges/Background.webp');
  background-repeat: no-repeat;
  background-size: cover;
}
.formContainer {
  background-color: white;
  padding: 35px;
}
.demoFields {
  margin-bottom: 20px;
}

.placeholder::placeholder {
  color: #d6d6d6;
}
.demoHttp::placeholder {
  text-align: center;
  color: #d6d6d6;
}

.demoLeftTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #9ff9ff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.demoRightTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  justify-content: space-between;
  background-color: #ecff9f;
}
.demoHeading {
  margin: 0;
  font-size: 16px;
  font-family: EnzoBold;
}
.demoDesc {
  margin: 0;
  font-size: 13px;
  margin-top: 5px;
  font-family: AzoLight;
}
.iconCard {
  padding: 20px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 20px;
  padding-left: 30px;
}
.playButton {
  position: absolute;
  left: 67%;
  top: 45%;
  z-index: 1;
  width: 6.5rem;
  height: 6.5rem;
  margin-top: -3.25rem;
  margin-left: -3.25rem;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: saturate(150%) blur(8px);
  backdrop-filter: saturate(150%) blur(8px);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: transform 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: transform 0.3s cubic-bezier(0.39, 0.575, 0.565, 1), -webkit-transform 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);

  cursor: pointer;
}
.demoCenterContainer {
  align-items: center;
  height: 400px;
  padding-block: 100px;
  text-align: center;
}
.demoCenterContainer > h1 {
  font-size: 50px;
}
.demoCenterContainer > p {
  color: #898989;
}

.centerContainer {
  background: linear-gradient(111.71deg, #3515a4 35.76%, #371d82 47.61%, #12053d 72.15%);
  border-radius: 50px;
  color: white;
  padding-top: 45px;
  padding-left: 60px;
  margin-block: 50px;
}

.centerContainer > div > div > h2 {
  font-weight: bolder;
  font-size: 45px;
  font-family: EnzoBold;
}
.centerContainer > p {
  color: #898989;
}
.demoMid {
  font-weight: 800;
  color: #005fcf;
  font-family: EnzoBold;
  font-size: 55px;
}
.contactMap {
  flex-direction: row;
  display: flex;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 6px 24px 0 rgba(174, 174, 186, 0.16);
  height: 480px;
  margin-bottom: 100px;
}
.contactnfo {
  padding: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.iconsRow > p {
  margin-top: 0px;
  margin-left: 50px;
  color: #727272;
}

.contactIcons {
  background-color: #005fcf;
  padding: 20px;
  margin-right: 10px;
  border-radius: 5px;
}
.commonRow {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
}
.commonRow > h4 {
  margin: 0px;
  font-weight: 600;
}
.playButton:hover {
  transform: scale(1.2); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.play {
  -webkit-transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.securityImg {
  position: absolute;
  left: 40em;
  height: 32em;
  border-radius: 0;
}
.aboutImg2 {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;

  margin-bottom: 0 !important;
  padding-bottom: 50px;
}
.cardTxt {
  color: #666666;
}
.mobView {
  padding: 30px;
  display: flex;
  width: 100%;
  height: 100%;

  margin-bottom: 30px;
}
.customCardHeading {
  font-weight: 700;
  font-family: EnzoBold;
  font-size: 1.35rem;
  color: #373737;
  padding-bottom: 0.5em;
  font-weight: 500;
}

.customCard {
  justify-content: center;
  min-height: 100% !important;
}

.customCard > div {
  min-height: 300px;
  min-width: 100%;
  margin-bottom: 30px;
  padding: 30px;
  border: 1px solid;
  background-color: white;

  border-color: #ececec;
  align-items: center;
  border-radius: 6px;
}
.aboutCard {
  min-height: 100% !important;
}

.moduleHeading {
  font-size: 4rem;
  color: #373737;
  font-family: EnzoBold;
  margin-bottom: 5px;
  font-display: swap;
}
.moduleSubHeading {
  color: #898989;
  font-size: 15px;
  margin-inline: 100px;
}
.headingCommon {
  font-size: 3rem;
  color: #373737;
  font-weight: bolder;
  font-family: EnzoLight;
  padding-top: 60px;
}
.secondaryHeading {
  font-size: 1.9rem;
  color: #373737;
  font-family: EnzoBold;
  margin-top: 60px;
  margin-bottom: 5px;
}
.bottomContainer {
  margin-block: 100px;
}

.headUnderline {
  height: 5px;
  border-radius: 50px;
  background-color: #005fcf;
}
.secdecoraion {
  height: 3px;
  background-color: #0c65ae54;
}
.atdecoraion {
  margin-top: 5px;
  height: 3px;
  background-color: #0c65ae54;
}
.glossarydecoraion {
  height: 3px;
  background-color: #0c65ae54;
}
.abtHeading {
  font-size: 2.35rem;
  color: #373737;
  font-weight: 700;
  font-family: AzoLight, sans-serif;
  margin-top: 60px;
  margin-bottom: 5px;
}
.glossaryHeading {
  font-weight: 700;
  font-family: AzoLight, sans-serif;
  font-display: swap;
  font-size: 2.35rem;
  color: #373737;
  margin-top: 30px;
  margin-bottom: 5px;
}
.aboutCard > div {
  display: flex;
  border: none;
  align-items: center;
  border-radius: 6px;
}
.secContainer {
  margin-top: 40px;
  padding: 2em;
  box-shadow: 0 6px 24px 0 rgba(174, 174, 186, 0.16);
  border-radius: 10px;
  padding-bottom: 100px;
  padding-top: 100px;
  background-color: white;
}
.colHeading {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #373737;
  font-family: EnzoBold;
}
.colText {
  padding-bottom: 55px;
  font-size: 15px;
  color: #898989;
}

.buttonHeading {
  color: #121212;
  font-weight: 600;
}

.outlineButton {
  color: #005fcf;
  border-radius: 5px;
  padding-inline: 10px;
  padding-block: 5px;
  border: 2px solid;
  width: 32%;
  text-align: center;
  margin-block: 10px;
  cursor: pointer;
  transition: 0.3s;
}
.outlineHeaderBtn {
  background-color: #005fcf;
  border: 1px solid #005fcf;
  color: #fff;
  border-radius: 25px;
  padding-inline: 15px;
  padding-block: 9px;
  text-align: center;
  margin-block: 10px;
  cursor: pointer;
  transition: 0.3s;
}
.outlineLoginBtn {
  border: 1px solid #484c50 !important;
  color: #484c50 !important;
  border-radius: 25px;
  padding-inline: 15px;
  padding-block: 10px;
  text-align: center;
  margin-block: 10px;
  cursor: pointer;
  transition: 0.3s;
  margin-right: 10px;
}
.outlineLoginBtn:hover {
  color: #7d7d7d !important;
  background-color: #f5f5f5 !important;
  border: 1px solid #7d7d7d !important;
}

.outlineButton:hover {
  background-color: #003d83;
  color: #fff !important;
}
.outlineHeaderBtn:hover {
  background-color: #003d83;
  color: #fff !important;
}
.outlineButton > p {
  text-decoration: none !important;
  font-weight: 600;
  margin: 0px;
}
.outlineHeaderBtn > p {
  text-decoration: none !important;
  font-weight: bold;
  padding: 0 !important;
  margin: 0 !important;
}
.headerLogo {
  margin-top: -20px;
}
.areebaHover {
  background-color: #005fcf;
  border-radius: 50px;
  /* margin-right: 10px; */
  /* padding-inline: 15px; */
  /* padding-block: 7px; */
}
.contactButton:hover {
  background-color: #004eaa;
}
.partnerButton {
  width: 100%;
  background-color: #005fcf;
  border-radius: 50px;
  margin-right: 10px;
  padding-inline: 10px;
  padding: 10px;
}
.partnerButton:hover {
  background-color: #004eaa;
}
.primaryBtn2:hover {
  background: #004eaa !important;
}
.primaryBtn2 {
  height: 50px;
  border: none !important;
  min-width: 400px;
  z-index: 5000;
  background-color: #005fcf;
  margin-right: 0.65em;
}
.primaryBtn:hover {
  background-color: #004eaa !important;
}
.areebaHover:hover {
  background-color: #004eaa;
}
.primaryBtnBanner2 {
  width: 100%;
  align-items: center;
  border-radius: 6px !important;
  border: none !important;
  min-width: 170px;
  justify-content: center;
  display: flex;
  height: 45px;
  background-color: #005fcf;
}
.primarybtnbanner2:hover {
  background-color: #111 !important;
}

.commonButton {
  background-color: #005fcf;
  color: #fff;
  padding-inline: 30px;
  padding-block: 15px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}
.commonButton:hover {
  background-color: #004eaa;
}
.commonButton > p {
  margin: 0px;
  letter-spacing: 0.1px;
  font-family: AzoLight;
  font-weight: 600;
}
.contactButton {
  background-color: #005fcf;
  color: #fff;
  width: 100%;
  padding-inline: 30px;
  padding-block: 15px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}

.contactButton > p {
  margin: 0px;
  letter-spacing: 0.1px;
  font-family: AzoLight;
  font-weight: 600;
}

.commonIconRow {
  flex-direction: 'row';
  display: flex;
  align-items: center;
  padding: 15px;
  font-weight: 600;
  font-size: 20px;
  padding-inline: 80px;
  margin-bottom: 20px;
}

.secText {
  padding-top: 55px;
  padding-bottom: 55px;
  padding-inline: 20px;
  font-size: 16px;
  color: #666666;
}
/* ---- */
.noContainer,
.track,
.track2 {
  white-space: nowrap;
  will-change: transform;
}

.moduleCardDesc,
.modulesCardTitle,
.step2 > div,
.step > div {
  text-align: center;
}
.cardCommon2 > div,
.cardCommon > div {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.footerUl,
.socialIcons,
.social_footer_ul {
  list-style-type: none;
}
.Breadcrumb > ol > li > a,
.dropdownItem:hover,
.footerUl li a:hover,
.moduleText > a,
.carouselContainer,
.marquee {
  direction: ltr;
}
.socialIcons {
  display: flex;
  line-height: 40px;
  margin-bottom: 0;
}
.socialIcons li {
  padding-right: 15px;
}
.inputContainer > div,
.modulesCardPadding {
  padding: 1em;
}
.MobSecImg {
  width: 100%;
  padding: 20px 20px 0;
}
.socialIcons li a {
  color: #666;
  font-size: 25px;
}
.homeCard {
  font-size: 25px;
  padding-top: 0;
  margin-top: 0;
}
.clientsHome {
  height: 50px;
}
.intTitle {
  margin: 0;
  color: #646464;
}

.intLogo {
  height: 80px;
  margin-bottom: 10px;
}
.modulesCardTitle {
  font-family: AzoLight, sans-sarif;
  font-weight: 600;
}
.dropdownHeader {
  font-family: AzoLight, sans-serif;
}
.moduleCardDesc {
  color: #6f7f8a;
  font-size: 13px;
}
.navbarContainer {
  padding: 0.5em 1em;
}
.noContainer {
  line-height: 36px;
  padding: 0 1em;
  font-size: 18px;
  align-items: center;
  color: #666;
}
.noContainer > span {
  color: #666;
}
.noContainer > a {
  text-decoration: none;
}
.primaryBtn {
  background-color: #005fcf !important ;
  border-radius: 25px;
  border: none !important;
  height: 40px;
  padding-inline: 65px;
  min-width: 100px;
  padding-bottom: 6px;
  z-index: 1001;
}

.secondaryBtn {
  padding-bottom: 6px !important;
}

.secondaryBtn {
  background: #fff !important;
  border-color: #d6d6d6 !important;
  border-radius: 6px !important;
  min-width: 100px !important;
  color: #585858 !important;
  transition: 0.5s ease-out !important;
}
.contentSearch {
  margin-top: 10px;
}
.secondaryBtn:active,
.secondaryBtn:focus,
.secondaryBtn:focus-within,
.secondaryBtn:hover,
.secondaryBtn:visited {
  background: linear-gradient(28.37deg, #3f4a55 2.81%, #708396 160.42%) !important;
  color: #fff !important;
  border-color: #fff !important;
  outline: 0 !important;
  box-shadow: none !important;
}
.HeaderContainer {
  position: sticky;
  top: 0;
  width: 100%;
  padding-top: 10px;
  padding-inline: 20px;
  border-radius: 16px;
  background-color: white;
  z-index: 1000;
  box-shadow: 0 15px 26px rgba(0, 0, 0, 0.1);
}

.track,
.track2,
.videoLayer {
  position: absolute;
}
.bannerImg,
.dropdownToggle {
  background-color: #fff !important;
}

.navbar_header > div {
  background-color: #fff;
}

.navDropdownIcon {
  margin-left: 10px;
  margin-bottom: 2px;
  color: #373737 !important;
}
.navDropdownIcon:hover {
  color: white !important;
}
.navItemDashboard {
  flex-direction: row;
  display: flex;
  padding: 0 0.9em 0;
  margin-top: 8px;
  color: #373737 !important;
}

.navItemLink {
  text-decoration: none !important;
}
.navItemInteraction {
  padding: 0 0.9em 1em;
  color: #373737;
  padding-block: 10px;
}
.dropdownStyleNew {
  padding-left: 0px;
  height: 100%;
  background-color: white !important;
  color: #6c757d !important;
  border: none !important;
  color: #373737;
  margin-top: 3px;
}

.newDropdownStyles > div {
  z-index: 10000;
  top: 30px !important;
  background-color: none !important;
}
.dropdownStyleNew:active {
  color: #373737 !important;
  background-color: #ebf2f8 !important;
}
.dropdownStyleNew:hover {
  color: #005fcf !important;
  background-color: #ebf2f8;
}

.clickedInteraction {
  padding: 0 0.9em 1em;
  color: #373737;
  padding-block: 10px;
  border-bottom: 4px solid #005fcf;
  border-radius: 4px;
}

.navItemInteraction:hover {
  background-color: #ebf2f8;
  cursor: pointer;
  padding-block: 10px;
  border-radius: 10px;
}

.LoginDashbaord {
  margin-top: 2px;
  color: #373737;
  padding-block: 5px;
  padding-inline: 20px;
}
.LoginDashbaord:hover {
  color: #fff;
  border-radius: 5px;
  background-color: #000;
}

.navItemDashboard > a {
  text-decoration: none !important;
  color: #373737 !important;
}
.linkStyle {
  text-decoration: none !important;
}
.linkLoginStyle {
  text-decoration: none !important;
}

.dropdownNewStyle {
  padding: 0 0.9em 0em;
  margin: 0;
}
.dropdownToggle {
  border: none !important;
  color: #373737 !important;
  box-shadow: none !important;
  padding: 0 !important;
  font-size: 16.5px !important;
  margin-top: 2px !important;
}
.dropdownHeader,
.step > div {
  color: #0c64ae;
  font-weight: 800;
}
.dropdownToggle:active,
.dropdownToggle:focus,
.dropdownToggle:hover {
  border-color: #fff !important;
  border: none;
  box-shadow: none !important;
  color: #373737 !important;
}
.dropdownHeader {
  font-size: 18px;
  letter-spacing: 0.5px;
}
.dropdownDesign {
  margin: 0 !important;
}
.dropdownMenu {
  width: 48em !important;
  padding: 2em 0 0 !important;
  left: -18em !important;
  margin-top: -10px !important;
  border: none !important;
  border-radius: 15px !important;
  box-shadow: 0 15px 26px rgba(0, 0, 0, 0.1);
}
.dropdownMenu2 {
  height: 400px;
  width: 33em !important;
  padding: 0 !important;
  left: -24.5em !important;
  z-index: 10000 !important;
  margin-top: -10px !important;
  border: none !important;
  padding-top: 2em !important;
  border-radius: 15px !important;
  box-shadow: 0 15px 26px rgba(0, 0, 0, 0.1);
}

.dropdownMenu3 {
  margin-top: -10px !important;
  width: 25em;
  margin-left: -3em;
  left: -4em !important;
  top: 1.5em;
  border-radius: 20px;
  text-align: center;
  border: none;
  box-shadow: 0 6px 17px 0 rgba(174, 174, 186, 0.14), 0 8px 24px 0 rgba(174, 174, 186, 0.16);
  padding: 2em !important;
  overflow: hidden;
}
.primaryBtnBanner,
.primaryBtnPartnerBanner {
  box-shadow: 5px 5px 25px rgba(43, 129, 199, 0.4), inset 5px 5px 25px rgba(52, 143, 218, 0.7);
  padding-bottom: 6px;
  min-height: 50px;
}
.bannerImg {
  width: 100%;
  margin: 0 auto !important;
  display: flex;
  align-items: center;
  border-radius: 0;
}
.primaryBtnBanner {
  background: linear-gradient(106.73deg, #157dd5 -3.32%, #0c64ae 93.37%);
  border-radius: 0 6px 6px 0 !important;
  border: none !important;
  margin: 0;
}
.primaryBtnFooter {
  gap: 10px;
  height: 47px;
  min-width: 200px;
  margin-top: -20px;
  margin-left: 10px;
  background: linear-gradient(92.38deg, #0c64ae 0, #2189e1 100%);
  border-radius: 6px;
}
.primaryBtnPartnerBanner {
  background: linear-gradient(106.73deg, #157dd5 -3.32%, #0c64ae 93.37%);
  border-radius: 25px !important;
  border: none !important;
  min-width: 200px;
  margin: 0;
}
.iconDevPartnerSection {
  padding-inline: 1em !important;
}
.affiliatedSection {
  height: 40;
  border-radius: 25px !important;
  background: linear-gradient(106.73deg, #ecf9ff -3.32%, #fff 93.37%);
  margin: 5px 0 10px !important;
  padding: 0.4em 2em 0 !important;
  align-items: center;
}
.primaryBtnBanner3 {
  background: linear-gradient(98.33deg, #005fcf 0, #005fcf 100%);
  padding-bottom: 6px;
  min-height: 45px;
  gap: 10px;
  width: 100%;
}

.primaryBtnBanner3 {
  justify-content: center;
  align-items: center;
  border-radius: 6px !important;
  border: none !important;
  margin: 10px 0 50px;
  min-width: 20%;
}
.secondaryBtnBanner {
  background: linear-gradient(28.37deg, #3f4a55 2.81%, #708396 160.42%) !important;
  color: #fff !important;
  min-width: max-content;
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
  border-radius: 6px;
  min-height: 50px;
}
.taglineStyling {
  max-width: auto !important;
  padding: 15px;
}
.BannerHeading {
  font-display: swap;
  font-family: EnzoBold;
  font-size: 3.7rem;
  color: #fff;
  font-weight: 700;
  margin-top: 0.5em;
}
.BannerSubHeading {
  font-display: swap;
  font-size: 1.2rem;
  color: #fff;
  margin-block: 20px;
}
.BannerParagraph {
  font-display: swap;
  font-family: 'AzoLight';
  font-size: 1.25rem;
  color: #708396;
}
.inputContainer {
  margin-top: 2em;
  width: 467px;
}
.emailInputFooter {
  border-radius: 6px;
  margin-left: 45%;
  margin-top: -20px;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  gap: 10px;
  min-width: 300px;
  height: 43px;
}
.emailInput {
  padding: 0.8em 1em !important;
  border-radius: 6px !important;
  box-shadow: 0 6px 17px 0 rgba(174, 174, 186, 0.14), 0 8px 24px 0 rgba(174, 174, 186, 0.16);
  border: 1px solid #ededed !important;
}
.appSizing {
  margin-top: 30%;
}
.step {
  padding: 2em;
  background: linear-gradient(136.15deg, rgba(255, 255, 255, 0.65) 22.66%, rgba(154, 154, 154, 0.65) 231.55%);
}
.step2 {
  padding: 2em;
  background: linear-gradient(347.77deg, rgba(255, 255, 255, 0.65) 34.98%, rgba(43, 129, 199, 0.65) 129.43%);
}
.step > div {
  font-display: swap;
  font-size: 22px;
  font-family: AzoLight, sans-serif;
}
.SectionHeading,
.step2 > div {
  font-weight: 500;
  font-family: AzoLight, sans-serif;
  font-display: swap;
}
.step2 > div {
  color: #4d4d4d;
  font-size: 36px;
}
.domainStyle {
  font-display: swap;
  line-height: 36px;
  background-color: #f6f6f6;
  padding: 0 1em;
  border-radius: 0.25rem;
  color: #898989;
  font-size: 15px;
}
.layer {
  background: linear-gradient(90deg, #fff 0, rgba(255, 255, 255, 0) 95.8%);
  height: 473px;
  padding: 4rem 1rem;
}
.marquee {
  direction: ltr;
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 94px;
  /* background-color: red; */
  overflow-x: hidden;
}
.content > img {
  margin: 0 1em;
}
.track {
  width: 100%;
  animation: marquee 32s linear infinite;
}
.track2 {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 22s linear infinite;
}
@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
.sectionContainer {
  margin: 0 auto 3em;
}
.SectionHeading {
  font-size: 2.35rem;
  color: #525252;
  padding-top: 40px;
}
.heading,
.headingGloss,
.headingInt,
.headingMobSec,
.headingModules,
.securityImg2,
.smHeading {
  font-weight: 600;
}
.SectionHeading > span {
  font-display: swap;
  color: #0c64ae;
}
.SectionSubHeading {
  font-display: swap;
  font-size: 20px;
  color: #525252;
  padding-top: 20px;
  padding-bottom: 0;
}
.learnMoreBtn {
  color: #525252 !important;
  transition: 0.5s ease-out;
  font-size: 18px;
}
.learnMoreBtn:hover {
  font-display: swap;
  font-weight: 600;
  cursor: pointer;
}
.cardSectionContainer {
  padding: 3em 25px 5em;
}
.smHeading {
  font-display: swap;
  font-size: 22px;
  line-height: 30.13px;
  color: #373737;
  font-family: AzoLight, sans-serif;
  align-self: stretch;
}
.heading,
.headingClient,
.headingGloss,
.headingInt,
.headingMobSec,
.headingModules {
  font-size: 36px;
  color: #373737;
  font-family: AzoLight, sans-serif;
}
.heading {
  font-display: swap;
  margin-top: 20px;
}
.headingMobSec {
  font-display: swap;
  margin-top: 5%;
}
.headingInt {
  font-display: swap;
  margin-left: 10px;
}
.headingGloss {
  font-display: swap;
}
.headingClient {
  margin-left: 10px;
  font-weight: 600;
}
.heading2,
.headingModule {
  font-size: 20px;
  font-weight: 600;
}
.integBtn,
.integBtn2 {
  background: linear-gradient(98.33deg, rgba(12, 100, 174, 0.1) 0, rgba(33, 137, 225, 0.1) 100%);
  color: #0c64ae;
  align-content: center;
}
.integBtn {
  align-items: center;
  flex: 1;
  width: 20%;
  margin: 30px 40% 0;
  border: none;
  justify-content: center;
}
.integBtn2:hover {
  box-shadow: 0 6px 24px 0 rgba(174, 174, 186, 0.16);
}
.integBtn2 {
  align-items: center;
  flex: 1;
  width: 100%;
  height: 40px;
  display: flex;
  border-radius: 10px;
  border: none;
  justify-content: center;
}
.cardDesign,
.contentCard {
  border-radius: 0.5em !important;
  margin-right: 1em;
  border-color: #e6e6e6;
  text-align: center;
}
.heading2,
.headingBig,
.headingModule {
  color: #333e63;
  font-family: AzoLight, sans-serif;
}
.heading2 {
  font-display: swap;
}
.headingBig {
  font-size: 2.35rem;
}
.cardDesign {
  min-width: 100%;
  min-height: 90%;
  margin-bottom: 2em;
}
.contentCard {
  min-width: 18rem !important;
  height: 80%;
}
.cardDesign > div,
.contentCard > div {
  padding: 0;
}
.cardDesign:hover {
  cursor: pointer;
  border-color: white;
  box-shadow: 0 1px 10px 0 rgba(174, 174, 186, 0.14), 0 8px 24px 0 rgba(174, 174, 186, 0.16);
}
.contentCard:hover {
  cursor: pointer;
  border: none;
  box-shadow: 0 1px 10px 0 rgba(174, 174, 186, 0.14), 0 8px 24px 0 rgba(174, 174, 186, 0.16);
}
.subTitle {
  text-align: center;
  font-size: 12px;
  font-family: AzoLight;
  line-height: 20px;
  letter-spacing: 0.02em;
  font-weight: 300;
}
.headingWeb,
.modulePrice,
.accBody,
.dropdownItem,
.forgot {
  text-decoration: none !important;
  font-weight: 400;
}
.cardImg {
  border-radius: 0.5em 0.5em 0 0;
  width: 100%;
}

.testimonialContainerParent {
  margin: 5em auto 0;
  background: #f8f9fa;
}
.testimonialContainer {
  width: 100%;
  text-align: center;
  background: #f8f9fa;
  border-radius: 1em;
  margin-top: 50px;
  margin-bottom: 50px;
}
.T2decoraion,
.decoraion,
.decoraion1,
.decoraion2,
.decoraion4,
.decoraionInt,
.decoraionMobSec {
  height: 3px;
  background-color: #0c65ae54;
  margin-bottom: 2.5em;
}
.reviewerImg {
  width: 85px !important;
  border-radius: 10px;
  margin-top: 2em;
  border: 1px solid #ededed;
  margin-bottom: 3.5rem;
}
.T2review,
.review {
  width: 72%;
  margin: 0 auto;
  line-height: 28px;
  font-size: 17px;
}
.T2review > span,
.review > span {
  font-size: 41px;
  padding: 0.4em;
}
.T2review > span > i,
.review > span > i {
  color: #ededed;
}
.reviewerName {
  font-size: 20px;
  color: #000302;
}
.T2reviewerName > span,
.reviewerName > span {
  font-weight: 600;
}
.testimonialCard {
  font-display: swap;
  width: 338px;
  height: 100%;
  margin-right: 50px;
  padding: 40px 20px 20px;
  border-radius: 10px;
}
.modulesImg {
  align-items: flex-end;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.testimonialCard h1 {
  font-display: swap;
  font-size: 24px;
  font-family: EnzoBold;
  margin-bottom: 12px;
}
.testimonialCard p {
  font-display: swap;
  font-size: 16px;
  font-family: AzoLight;
}
.homeOldNew {
  width: 90%;
  flex: 1;
  justify-content: center;
  margin-bottom: 50px;
  align-items: center;
  display: flex;
  align-content: center;
}
.intigrationContainer {
  width: 100%;
  margin: 0 auto;
  padding: 2em 0 30px;
  background-color: #fafafa;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
}
.intigrationContainerInner {
  padding: 3em 25px;
  overflow: hidden;
}
.integrationCol {
  padding-left: 0.5em;
  flex: 1;
  flex-direction: column;
}
.cardCommon,
.cardCommon1,
.cardCommon2 {
  margin-bottom: 1.5em;
  justify-content: center;
}
.cardCommon,
.cardCommon2 {
  border-radius: 0.5em;
  border: none;
}
.cardCommon1 {
  border-radius: 0.5em;
  border: 1px solid #ececec;
}
.cardCommon1 > div,
.cardCommon2 > div {
  display: flex;
  justify-content: center;
  text-align: center;
}
.cardCommon1 > div {
  align-items: center;
  border-radius: 10px;
}
.cardCommon2 > div {
  align-items: center;
  border-radius: 6px;
}
.cardCommon > div,
.hrSection {
  justify-content: center;
  display: flex;
}
.cardCommon > div {
  align-items: center;
  border-radius: 10px;
  text-align: center;
}
.hrSection {
  flex-direction: column;
}
.cardDesignIntigrations {
  margin-right: 1em;
  margin-top: 0.45em;
  width: 95px;
  height: 95px;
  border-radius: 0.5em !important;
}
.cardDesignIntigrations > div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.cardDesignIntigrations:hover {
  cursor: pointer;
}
.footer {
  min-height: 400px;
  color: #6c757d;
  background-color: #f8f9fa;
}
.homeClients {
  /* background-color: blue; */
  margin-top: 3rem;
}
.footerleft {
  margin-top: 3em;
  padding: 0 36px;
}
.logofooter {
  width: 100px;
  margin-bottom: 30px;
}
.demoDiv,
.forgot,
.slider,
.videoLayer {
  width: 100%;
}
.footerleft p {
  color: #484c50;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  font-family: AzoLight;
  font-display: swap;
  margin-bottom: 20px;
}
.footerleft p i {
  width: 20px;
  font-display: swap;
  color: #484c50;
}
.paddingtopBottom {
  margin-top: 3em;
  padding: 0 36px;
}
.paddingtopBottom > p {
  /* font-family: AzoLight; */
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
}
.headingsFooter {
  /* font-family: Az; */
  font-size: 15px;
  color: #121212;
  font-weight: 600;
  margin-bottom: 14px;
}
.forgot {
  display: flex;
  padding: 0 0 0 3rem !important;
  color: #212529;
}
.footerUl {
  padding-left: 0;
  margin-left: 2px;
  color: #484c50;
}
.footerUl li h2 {
  line-height: 30px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
}
.footerUl li a {
  font-family: AzoLight;
  text-decoration: none !important;
  color: #484c50;

  transition: color 0.2s linear, background 0.2s linear;
}
.footerUl i {
  margin-right: 10px;
}
.footerUl li a:hover {
  color: #c2c2c2;
}
.social_footer_ul {
  display: table;
  margin: 15px auto 0;
}
.social_footer_ul li {
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 30px;
  float: left;
}
.social_footer_ul li a {
  color: #ccc;
  border: 1px solid #6c757d;
  padding: 8px;
  border-radius: 50%;
}
.social_footer_ul li i {
  width: 20px;
  height: 20px;
  text-align: center;
}
.copyright {
  min-height: 50px;
  background-color: #121212;
  text-align: center;
}
.copyright > div > div > p {
  font-size: 15px;
  text-align: center;
  color: #ffffff;
  padding: 20px 0;
  margin-bottom: 0 !important;
}
.accordionHeader {
  text-align: justify;
  box-shadow: 0 6px 17px 0 rgba(174, 174, 186, 0.05), 0 8px 24px 0 rgba(174, 174, 186, 0.1);
  cursor: pointer;
  background-color: #fff;
  margin: 1em 0;
  border: none !important;
  border-radius: 0.5em !important;
}
.applogo,
.btnsContainer,
.dragSpan,
.sectionTitle,
.taglineText {
  text-align: center;
}
.accordionHeader > div {
  background: #fff;
  font-size: 17px;
  font-weight: 500;
}
.accordionHeader > h2 > button {
  background-color: white !important;
  color: #000302 !important;
}
.accBody {
  font-size: 16px;
  color: #8e8e8e;
}

.dropdownItem {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  color: #212529;
}
.dropdownItem:hover {
  background-color: #ededed !important;
  color: #212529;
  border-radius: 5px;
}
.dropdownItem:active {
  border-radius: 5px !important;
  color: #212529 !important;
  background-color: #ededed !important;
}
.navItemDashboard,
.navItemDashboard:hover {
  color: #212529;
  text-decoration: none;
  cursor: pointer;
}
.videoLayer {
  height: 800px;
  background: linear-gradient(360deg, #f8f8f8 -40.87%, rgba(248, 248, 248, 0) 50%);
  bottom: 2px;
}
.mobileImg,
.slider,
.switch {
  position: relative;
}
.containerTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #3f3d56;
  margin-left: 0.25em;
  margin-bottom: 6px;
}
.sectionTitle {
  font-weight: 600;
  color: #005fcf;
  font-size: 60px;
  margin: 11px 0 0;
  line-height: 79px;
}
.slider {
  margin: auto;
  -webkit-appearance: none;
  overflow: hidden;
  height: 40px;
  cursor: pointer;
  border-radius: 10px;
  background: #8c1717;
  outline: 0;
}
.slider::-webkit-slider-runnable-track {
  background: #f7f7f7;
}
.slider::before {
  content: '0 1 2 3 4 5 6 7 8 9';
  position: absolute;
  text-align: center;
  color: #a40000;
  font-size: 1em;
  /*Serves to spread the letters to fit the whole width of the track */
  letter-spacing: 0.65em;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.6em;

  /* Makes the letters look embossed adding to the skeumorphic theme of the design */
  text-shadow: 0.5px 0.5px 0.9px rgba(0, 0, 0, 0.4);

  z-index: -1;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 70px;
  height: 40px;
  background: #fff;
  box-shadow: 0px 0px 28.2385px rgba(0, 0, 0, 0.3);
  border: 3px solid #999;
  border-radius: 20px;
}
.slider::-moz-range-track {
  height: 40px;
  background: #f7f7f7;
}
.slider::-moz-range-thumb {
  background: #fff;
  height: 40px;
  width: 20px;
  border: 3px solid #999;
  border-radius: 0 !important;
  box-shadow: -100vw 0 0 100vw #1e90ff;
  box-sizing: border-box;
}
.directoryList {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  margin-top: 120px;
  margin-inline: 100px;
}
.Directoryhover {
  padding-inline: 10px;
  transition: 0.3s;
}
.Directoryhover:hover {
  padding-inline: 10px;
  border-radius: 50px;
  background-color: #edeeee;
}
.grayscale {
  cursor: 'pointer';
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
}
.grayscale:hover {
  filter: none;
  -webkit-filter: grayscale(0%);
}
.slider::-ms-fill-lower {
  background: #1e90ff;
}
.slider::-ms-thumb {
  background: #fff;
  border: 2px solid #999;
  height: 40px;
  width: 20px;
  box-sizing: border-box;
  border-radius: 10px;
}
.slider::-ms-ticks-after {
  display: none;
}
.slider::-ms-ticks-before {
  display: none;
}
.slider::-ms-track {
  background: #f7f7f7;
  color: transparent;
  height: 40px;
  border: none;
}
.webImg {
  padding-top: 5em;
}
.demoContainer2,
.pricingPlan2 {
  padding: 50px;
  margin-top: 40px;
  box-shadow: 0 6px 24px 0 rgba(174, 174, 186, 0.16);
  background-color: #fff !important;
}
.pricingModules {
  padding: 50px;
  margin-top: 40px;
  margin-left: 20px;
  box-shadow: 0 6px 24px 0 rgba(174, 174, 186, 0.16);
  background-color: #fff !important;
}
.headingWeb {
  font-size: 42px;
  font-weight: 100;
  color: #666;
}
.modulePrice {
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: 700;
}
.clientsSec1,
.sectionDisplay2 {
  display: contents;
}
.clientsSec2,
.sectionDisplay,
.switchInput {
  display: none;
}
.switchBlueInput {
  display: none;
}
.demoContainer,
.demoContainer2,
.dragSpan {
  color: #b5b5b5;
  margin-top: 0.2em;
}
.switch {
  display: inline-block;
}
.switchLabel {
  display: block;
  width: 37px;
  height: 20px;
  text-indent: -150%;
  clip: rect(0 0 0 0);
  color: #646464;
  user-select: none;
  margin: 0;
}
.switchLabelDisbaled {
  display: block;
  width: 37px;
  height: 20px;
  text-indent: -150%;
  clip: rect(0 0 0 0);
  color: #646464;
  user-select: none;
  margin: 0;
}
.switchLabel:after,
.switchLabel:before {
  content: '';
  display: block;
  position: absolute;
  cursor: pointer;
}
.switchLabel:before {
  width: 100%;
  height: 100%;
  background-color: #dedede;
  border-radius: 9999em;
  transition: background-color 0.25s;
}
.switchLabelDisbaled:after,
.switchLabelDisbaled:before {
  content: '';
  display: block;
  position: absolute;
  cursor: pointer;
}
.switchLabelDisbaled:before {
  width: 100%;
  height: 100%;
  background-color: #dedede;
  border-radius: 9999em;
  transition: background-color 0.25s;
}
.form-control {
  border: #000;
}
.switchLabel:after {
  top: 2.5px;
  left: 3px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
  transition: left 0.25s;
}
.switchLabelDisbaled:after {
  top: 2.5px;
  left: 3px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
  transition: left 0.25s;
}
.switchInput:checked + .switchLabel:before {
  background-color: #4fc123;
}
.switchInput:checked + .switchLabel:after {
  left: 19px;
}

.switchBlueInput:checked + .switchLabel:before {
  background-color: #005fcf;
}
.switchBlueInput:checked + .switchLabel:after {
  left: 19px;
}
.switchLabelDisbaled:after {
  top: 2.5px;
  left: 3px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
  transition: left 0.25s;
}
.switchInput:checked + .switchLabelDisbaled:before {
  background-color: #b9e6a7;
}
.switchInput:checked + .switchLabelDisbaled:after {
  left: 19px;
}

.switchBlueInput:checked + .switchLabelDisbaled:before {
  background-color: #005fcf;
}
.switchBlueInput:checked + .switchLabelDisbaled:after {
  left: 19px;
}
.monthlySwitch {
  margin-top: -100px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 43px;
  line-height: 0;
}
.bannerImg2 {
  height: 100%;
  width: 100%;
}

.pricingBtn {
  background: white;
  border: 2px solid #005fcf;
  border-radius: 6px;
  min-width: 100px;
  color: #005fcf;
  height: 50px;
  width: 200px;
  transition: 0.5s ease-out !important;
  padding-bottom: 6px !important;
}
.pricingBtn:hover {
  background: #005fcf white !important;
  color: white !important;
}
.switchMain {
  display: flex;
}
.employeeTitle {
  font-weight: 300;
  color: #666;
  font-size: 38px;
  line-height: 50px;
  padding: 0.6em 0;
}
.employeePadding {
  padding: '0 1.5em 1.5em 0';
}
.employeeBox {
  color: #666;
  border-color: #b7b7b7;
  border-radius: 5px;
  margin-left: 10px;
  padding-right: 0;
  padding-left: 10px;
  width: auto;
}
.table,
.td,
.th {
  border: 1px solid #d6d6d6;
  border-collapse: collapse;
  padding: 1em;
}
.stepsForm {
  margin: 40px auto 3em;
}
.stepInnerForm {
  width: 70%;
  margin: 0 auto;
}
.contactSales {
  color: #646464;
  font-size: 38px;
  padding-left: 1.3em;
}
.btnsContainer {
  padding: 0 1.5em;
}
.sectionBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3em;
}
.mobileBtn {
  position: absolute;
  bottom: 45px;
  right: 45px;
}
.searchable {
}
.MobileCol {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}
.MobileCol2 {
  justify-content: right;
  margin-left: 20%;
  width: 50%;
  margin-bottom: 220px;
}
.headingMobile,
.headingMobile2 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #6f7f8a;
  font-family: AzoLight, sans-serif;
}
.collapsible {
  justify-content: center;
  width: 100% !important;
}
.headingMobile {
  display: none;
}
.demoDiv2,
.footerEmail,
.googleBtn {
  display: flex;
}
.flex,
.pricingHeadingContainer {
  display: flex;
  justify-content: space-between;
}
.taglineText {
  color: #fff;
  font-size: 26px;
  font-weight: 500;
  font-family: AzoLight, sans-serif;
  max-width: 1300px;
  margin: 0 auto;
}
.googleBtn,
.paymentHeading {
  font-weight: 600;
}
.accordionLabel {
  padding: 0 !important;
  color: #646464 !important;
  font-size: 18px !important;
  text-decoration: none !important;
  margin-top: 0.5em !important;
  cursor: pointer;
}
.moduleText,
.paymentHeading {
  font-size: 18px;
  color: #646464;
}
.footerEmail {
  flex-direction: row;
}
.accordionLabel > span {
  margin-left: 0.25em !important;
}
.creditCard > div,
.creditCard > div > div {
  margin: 0 !important;
  width: 100% !important;
}
.moduleText {
  margin-left: 1em;
  line-height: 35px;
  margin-bottom: 1rem;
}
.moduleText > a {
  color: #646464;
}
.applogo {
  padding: 15px 40px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 0;
}
.payemtnContainer {
  padding: 2em;
  box-shadow: 0 6px 24px 0 rgba(174, 174, 186, 0.16);
  background-color: #fff;
  border-radius: 1em;
}
.creditCard {
  margin-top: 2em !important;
}
.creditCard > div > div {
  height: 225px !important;
}
.googleBtn {
  width: 100%;
  height: 45px;
  border: 1px solid #eee;
  background: #fff;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1370px) {
  .mobileBtn {
    margin-top: 6em;
    position: relative;
    text-align: end;
    display: none;
  }

  .centerContainer > div > div > h2 {
    font-size: 35px;
    margin-top: 0;
  }

  .centerContainer > div > div > p {
    font-size: 12px !important;
  }
  .centerContainer {
    padding-top: 35px;
    text-align: center;
    height: 350px !important;
  }
  .centerContainer > div > div > div {
    display: flex;
    justify-content: center;
  }
  .NewHeaderheader {
    /* padding-inline: 20px; */
  }
  .glossaryBG {
    padding-inline: 20px;
  }
  .sectionPadding {
    margin-right: 0;
    margin-left: 0;
    padding: 0;
  }
  .MobileCol,
  .MobilePadding {
    padding: 0 2em 2em;
  }
  .MobileCol {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cardSectionContainer {
    padding: 0 2.5em;
  }
  .sectionContainer {
    margin: 0 auto 3em;
  }
  .intigrationContainerInner {
    padding: 3em 25px;
  }
  .contactSales {
    font-size: 30px;
    margin-top: 100px;
  }
  .demoContainer,
  .demoContainer2,
  .pricingPlan,
  .pricingPlan2 {
    margin: 10px;
  }
  .cardCommon,
  .cardCommon1 {
    margin-right: 0;
    height: 150px;
    min-width: 100% !important;
  }
  .intigrationContainer {
    padding-bottom: 0;
  }
  .integBtn {
    margin-bottom: 30px;
  }
  .directoryList {
    padding: 0 30px;
  }
  .cardCommon {
    margin-left: 15px;
    padding-bottom: 20px !important;
  }

  .modulesInteraction {
    margin: 0;
  }

  .cardCommon1 {
    padding-right: 0;
    margin-left: 12px;
  }
  .headingModule {
    margin-top: 30px;
  }
  .decoraion {
    margin-left: 25px !important;
  }
  .heading3,
  .heading4 {
    margin-top: 40px;
  }
  .applogo {
    margin-left: 0;
  }
}

/* ======= set flexble header for all screens ===== */
@media screen and (max-width: 1514px) {
  .headerDropdown > div {
    /* width: 100% !important;   */
    /* width: 100vh !important;   */
  }
  .headerDropdown {
    /* left:-150% !important; */
    width: 70em !important;
  }
}

@media screen and (max-width: 1455px) {
  .headerDropdown > div {
    /* width: 100% !important;   */
    /* width: 100vh !important;   */
  }
  .headerDropdown {
    /* left:-150% !important; */
    /* width: 70em !important;   */
  }
}

@media screen and (max-width: 1360px) {
  .headerDropdown > div {
    /* width: 100% !important;   */
    /* width: 100vh !important;   */
  }
  .headerDropdown {
    /* left:-150% !important; */
    width: 60em !important;
  }
}

@media screen and (max-width: 1170px) {
  .headerDropdown > div {
    /* width: 100% !important;   */
    /* width: 100vh !important;   */
  }
  .headerDropdown {
    /* left:-150% !important; */
    width: 55em !important;
  }
}

@media screen and (max-width: 1075px) {
  .headerDropdown > div {
    /* width: 100% !important;   */
    /* width: 100vh !important;   */
  }
  .headerDropdown {
    /* left:-150% !important; */
    width: 45em !important;
  }
}

@media screen and (max-width: 991px) {
  .headerDropdown > div {
    /* width: 100% !important;   */
    /* width: 100vh !important;   */
  }
  .headerDropdown {
    /* left:-150% !important; */
    width: 100% !important;
    margin-top: 0px !important;
    height: 90%;
    overflow: hidden;
  }
}
@media screen and (max-width: 991px) {
  .dropdownMenu3 {
    margin-left: 0px;
    width: 22em;
  }
}

/* ================================ */

@media screen and (max-width: 992px) {
  .demoContainer,
  .demoContainer2,
  .pricingPlan,
  .pricingPlan2 {
    box-shadow: none;
    padding: 10px;
  }
  .commonDemoForm {
    justify-content: center;
  }
  .bannerImg {
    background: #fff !important;
  }
  .cardCommon2 {
    min-width: 100%;
  }
  .cardCommon {
    min-height: 100%;
    padding-bottom: 30px;
  }
  .dropdownMenu {
    width: 100% !important;
    margin: 0;
    left: -6em;
  }
  .cardDesign {
    padding: 0;
  }
  .demoContainer {
    display: flex;
    flex-direction: column;
  }
  .demoDiv2 {
    flex-direction: column;
    justify-content: center;
  }
  .domainStyle {
    margin: 0 !important;
    text-align: center;
  }
  .demoContainer2 {
    top: -40px;
    margin-bottom: 0 !important;
  }
  .demoHttp {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .stepHome {
    margin-bottom: 0;
  }
  .dropdownMenu2 {
    width: 100% !important;
    margin: 0;
    left: -26.5em;
    z-index: 10000;
    padding: 1em;
  }
  .aboutSideDropdown {
    width: 100% !important;
    /* margin: 0; */
    left: -26.5em;
    z-index: 10000;
    padding: 0;
  }
  .navbarContainer {
    padding-right: 0;
  }
  .contactSales {
    margin-top: -50px;
  }
  .footerEmail {
    padding: 20px;
  }
  .MobileCol2 {
    width: 70%;
    padding: 0;
    margin: 0 !important;
  }
  .heading4 {
    margin-left: 10px;
  }
  .homeCard {
    font-size: 20px;
    margin-bottom: 0px;
  }
  .decoraion {
    margin-left: 15px !important;
  }
  .modulesImg {
    width: 100%;
    margin-left: 0;
  }
  .abtMobText {
    font-size: 18px;
    color: #626c78;
  }
  .securityImg2 {
    display: flex;
    width: 100%;
    height: 80%;
    justify-content: center;
    margin-bottom: 0 !important;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 768px) {
  .pricingPlan,
  .pricingPlan2,
  .glossaryBG {
    background: none;
    padding-top: 150px;
  }
  .responsiveBannerImage {
    width: 100%;
    object-fit: cover;
  }
  .leftQucikModule {
    margin-left: 0px;
  }
  .SvgResponsive {
    height: 50px;
    width: 50px;
  }
  .MobileResponsive {
    width: 100%;
  }
  .primaryBtnBanner {
    box-shadow: none;
  }
  .atdecoraion {
    margin-left: 20px;
  }
  .mobView {
    padding-top: 0;
    margin-top: 0;
  }
  .secText {
    margin-top: 0px;
    padding-top: 0px;
  }
  .abtHeading {
    padding-left: 20px;
    margin-top: 30px;
  }
  .glossaryHeading {
    margin-top: 30px;
  }
  .bgImg,
  .clientsSec1,
  .headingMobile2,
  .moduleCardDesc,
  .socialIcons {
    display: none;
  }
  .sectionImgGloss {
    margin-top: 0 !important;
  }
  .modulesImg {
    margin-top: 10px !important;
    padding-top: 0 !important;
    margin-bottom: 10%;
  }
  .iconText {
    font-size: 17px;
  }
  .sectionContent,
  .sectionContent2,
  .sectionContent3 {
    font-size: 18px !important;
    color: #666;
    white-space: pre-line;
  }
  .sectionCol2 {
    padding: 0 !important;
    margin: 0 !important;
  }
  .sectionContentImgRow2 {
    margin-left: 0 !important;
    padding-top: 0px !important;
    justify-content: center;
  }

  .sectionContentImgRow3 {
    margin-left: 0 !important;
    padding-top: 0 !important;
  }
  .sectionContent {
    margin-top: 20px !important;
  }
  .sectionCol {
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding-left: 0 !important;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .sectionContent3 {
    padding: 0 !important;
  }
  .sectionDisplay2 {
    display: none !important;
  }
  .sectionDisplay {
    display: contents !important;
    position: relative;
  }
  .sectionList {
    margin-top: 20px !important;
    margin-bottom: 5px !important;
  }
  .videoThumbImg {
    margin-top: 0 !important;
    margin-bottom: 30px;
    padding-top: 0 !important;
  }
  .modulesCardPadding {
    padding-top: 10px;
    margin-bottom: 5px;
  }
  .contentSearch {
    margin-top: -20px;
  }
  .taglineText {
    font-size: 25px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .intigrationContainer {
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 0;
  }
  .intigrationContainerInner {
    padding-top: 20px;
    padding-bottom: 0;
  }
  .glossaryHeader > div > h1 {
    font-size: 45px !important;
  }
  .glossaryHeader {
    height: 140px !important;
  }
  .Breadcrumb {
    margin-top: -80px;
  }
  .searchable {
    margin-top: -30px;
  }
  .sectionPadding {
    margin-right: 0;
    margin-left: 0;
    padding: 0;
  }
  .step,
  .step2 {
    background: #fff;
  }
  .stepTxt {
    font-display: swap;
    font-size: 20px;
  }
  .cardDesignIntigrations {
    width: 100%;
    height: 100px;
  }

  .cardDesign,
  .integrationCol {
    padding: 0;
  }
  .MobileCol {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2em 2em;
  }
  .MobileCol2 {
    width: 100%;
    margin-right: 10px;
  }
  .mobileBtn {
    position: relative;
    text-align: center;
    width: 100% !important;
    margin: 6em auto 0 !important;
  }
  .mobileImg > img {
    padding: 0 !important;
    margin: 0;
  }
  .inputContainer {
    margin-top: 1em;
    width: 100%;
  }
  .primaryBtnBanner,
  .secondaryBtnBanner {
    width: 100% !important;
    border-radius: 6px !important;
    margin-bottom: 1em;
    padding: 0;
  }
  .secondaryBtnBanner {
    margin-left: 0 !important;
  }
  .emailInput {
    border-radius: 6px !important;
    margin-bottom: 1em;
    text-align: center;
  }
  .stepHome {
    margin-top: 35%;
  }
  .bannerImg2 {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
  }
  .bannerImg {
    padding: 2rem 0 !important;
  }

  .BannerSubHeading {
    margin-top: 0.5em;
    color: #6f7f8a !important;
    font-weight: 500;
    font-size: 20px;
  }
  .navbarContainer {
    padding: 0.5em 0;
  }
  .navbarContainer > div {
    margin-left: 0;
  }
  .testimonialContainerParent {
    padding: 0 1em;
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
  .stepInnerForm {
    width: 100%;
  }
  .layer {
    padding: 4rem 0;
    padding-bottom: 0 !important;
  }
  .sectionBlock {
    padding: 0 0 2em;
  }
  .navbar_header {
    padding: 0.5rem 0 !important;
  }
  .navItemDashboard {
    padding-left: 0;
    padding-right: 0;
  }
  .navItemDashboard:first-child {
    padding-top: 0.5em;
  }
  .sectionContainer {
    padding: 0 2em;
    margin-bottom: 0;
  }
  .mobileImg {
    padding: 0 1em;
  }
  .primaryBtn2 {
    min-width: 100%;
  }
  .pricingBtn {
    margin-top: 0.5em;
    width: 100%;
  }
  .decoraion {
    margin-left: 45px !important;
  }
  .contactSales {
    justify-content: center;
    text-align: center;
    font-size: 30px;
    padding: 0;
  }
  .clientsSec2,
  .headingMobile {
    display: contents;
  }
  .appSizing {
    padding-top: 0;
    margin-top: 10%;
  }
  .applogo {
    padding: 20px;
    margin: 20px 20px 20px 0;
    width: 60%;
  }
  .integBtn2 {
    width: 90%;
    margin: 35px 35px 0;
    margin-top: 0 !important;
  }
  .modulesCardTitle {
    margin: 0;
    padding: 0;
  }
  .mobileImg {
    margin-bottom: 0;
  }
  .clientsHome {
    height: 20px;
    margin-top: 0;
    padding: 0;
  }
  .aboutWebHRIcons {
    width: 40px;
    margin-right: 10px;
  }
  .customCard > div {
    min-height: 200px;
  }
  .marquee,
  .sectionImg {
    margin: 0 !important;
    padding: 0 !important;
  }
  .sectionImg {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .homeClients {
    margin-top: 1em !important;
    margin-bottom: 0 !important;
  }
}
@media screen and (max-width: 655px) {
  .homeIntegrationTitle {
    padding-top: 0px;
  }
  .demoCenterContainer {
    height: 500px;
  }
  .NewBannerBG {
    padding-left: 20px;
  }
  .contentBreadcrumb {
    margin: 0;
  }
  .moduleSubHeading {
    margin-inline: 0px;
  }
  .headerDropdown > div {
    /* width: 100%; */
  }
  .contactLiveDiv {
    padding: 30px;
  }
  .contentSearch {
    margin-block: 50px;
  }
  .switchMain {
    margin-bottom: 10px;
    justify-content: center;
    margin-left: 15px;
    justify-content: center;
  }
  .layoutHeading {
    padding-bottom: 10px;
  }
  .sectionTitle {
    font-size: 54px;
    padding: 7px 14px;
  }
  .centerContainer {
    margin: 0 !important;
    padding-left: 10px !important;
    height: 200px !important;
  }
  .pricingModules {
    box-shadow: none;
    padding: 10px;
  }
  .moduleReview {
    padding: 20px;
  }
  .moduleQuoteBG {
    background-image: none;
    background-color: #efffe9;
  }
  .reviewImages {
    width: 150px;
    margin-top: 20px;
  }
  .BannerHeading {
    margin-top: -30px;
    font-size: 40px;
    font-weight: 600;
  }
  .cardCommon,
  .cardCommon1 {
    height: 120px !important;
  }
  .intLogo {
    height: 70px;
    margin-bottom: 10px;
  }
  .intTitle {
    font-size: 11px;
    margin-bottom: 0;
  }
  .headingMobSec {
    margin-top: 50px;
    font-size: 25px;
  }
  .headingMobile {
    font-size: 15px;
  }
  .BannerHeading br {
    display: none;
  }
  .employeeTitle {
    font-size: 30px;
    padding: 0;
  }

  .homeCard {
    font-size: 18px;
  }
  .step > div,
  .stepTxt {
    font-size: 16px;
  }
  .demoContainer {
    padding: 1em;
  }
  .pricingHeadingContainer {
    display: block;
  }
  .cardDesign {
    padding: 0;
    min-width: 100% !important;
  }
  .monthlySwitch {
    margin-top: 0px;
    margin-bottom: 0px;
    justify-content: center;
    margin-top: 30px;
  }
  .cardDesign > div {
    padding-left: 0;
    padding-right: 0;
  }
  .primaryBtn2 {
    width: 100%;
  }
  .pricingBtn {
    margin-top: 0.5em;
    width: 100%;
  }
  .btnsContainer {
    padding: 0;
  }
  .pricingPlan,
  .pricingPlan2 {
    box-shadow: none;
  }
  .decoraion {
    margin-left: 45px !important;
  }
  .QuickModule {
    margin: 0px;
    padding: 0px;
    font-size: 10px;
  }
  .QuickModule > div {
    padding-inline: 15px;
  }
  .integBtn2 {
    width: 90%;
    margin-bottom: 0 !important;
    margin: 25px;
  }
  .commonDemoForm > div > input {
    width: 200px;
    margin-right: 0px !important;
  }
  .commonDemoForm > div > div {
    margin: 0 !important;
  }
  .modulesCardPadding {
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 5px;
  }
  .integBtn {
    align-items: center;
    flex: 1;
    width: 100%;
    margin: 0 0 30px;
    height: 40px;
    display: flex;
  }
  .stepHome {
    margin-top: 30%;
  }
  .stepTxt {
    font-display: swap;
  }
  .SectionHeading {
    font-size: 2em;
  }
  .SectionSubHeading {
    font-size: 15px;
    margin-bottom: 0 !important;
  }
  .homeOldNew {
    margin-bottom: 10px;
    margin-top: 0;
    padding: 0;
  }
  .applogo {
    padding: 10px;
  }
  .MobSecImg {
    margin-right: 0;
  }
  .iconText {
    font-size: 14px;
    margin: 0;
  }
  .clientsNewSpacing {
    margin: 0px;
  }
}

.T2bannerBtn,
.T2emailInput {
  box-shadow: 0 6px 17px 0 rgba(174, 174, 186, 0.14), 0 8px 24px 0 rgba(174, 174, 186, 0.16);
}
.CookieConsent > div {
  flex: inherit !important;
}
.videoThumbImg {
  border-radius: 1em;
  width: 100%;
  cursor: pointer;
}
.videoModal {
  margin-bottom: -6px;
  border: 2px solid #eee;
  border-radius: 1em;
  outline: 0;
  width: 100%;
}
.sectionCol,
.sectionCol2 {
  padding: 4em 0;
  display: flex;
  align-items: center;
}
.sectionCol2x1 {
  padding: 4em 0;
  align-items: center;
}
.sectionContent,
.sectionContent2,
.sectionContent3 {
  font-size: 22px;
  color: #666;
  white-space: pre-line;
}
.sectionContentImgRow3 {
  border-radius: 1em;
  width: 70%;
  margin-left: 40px;
  padding: 4em 0;
}
.sectionContentImgMob {
  border-radius: 1em;
  width: 50%;
  padding-top: 10px;
  margin-left: 20%;
}
.sectionContentImgRow2 {
  border-radius: 1em;
  width: 25em;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 4em 0;
}
.sectionImg,
.sectionImgGloss {
  padding: 0;
  width: 80%;
  display: flex;
}
.sectionImgGloss {
  margin: 20px 0 0;
  justify-content: start;
  flex-direction: column;
  flex: 1;
}
.sectionImg {
  margin: 40px;
  justify-content: start;
  flex-direction: column;
  flex: 1;
}
.sectionImgOnly {
  border-radius: 1em;
  width: 100%;
  padding: 4em 0;
}
.sectionImgOnly2 {
  border-radius: 1em;
  width: 100%;
  padding: 0 0 4em;
}
.sectionIconCol {
  flex-direction: column;
}
.iconText2 {
  margin-left: 10px;
  font-size: 13px;
  font-weight: bold;
}
.sectionList2 {
  background-color: #fff;
  border: 1px solid #dcdcdc;
  font-size: 1.1rem;
  padding: 10px;
  color: #333e63;
  height: 80%;
  align-items: center;
  display: flex;
  flex: 1;
  text-align: center;
  flex-direction: column;
}
.sectionIcon2 {
  color: linear-gradient(180deg, #053862 0%, #2189e1 100%);
  margin-top: 10px;
  /* justify-content: center;
  align-items: center; */
  padding-bottom: 10px;
}
.sectionList {
  background-color: #fff;
  border: 1px solid #dcdcdc;
  font-size: 1.1rem;
  padding: 10px;
  color: #333e63;
  height: 80%;
  align-items: center;
  display: flex;
  flex: 1;
  text-align: center;
  flex-direction: column;
}
.sectionIcon {
  color: linear-gradient(180deg, #053862 0%, #2189e1 100%);
  margin: 0;
  padding: 0;
}
.bgFeatures {
  background: linear-gradient(to bottom right, #2154cf, #6ec5ff);
  position: relative;
  overflow: hidden;
  padding: 3em;
  margin-bottom: 7em;
}
.bgfeaturesOverlay {
  background: url('https://cdn.webhr.co/Web/LandingPages/LandingPage1/images/wave-mask.png') center center/cover no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.featuresHeading {
  line-height: 1.4;
  font-size: 1.75rem;
  color: #fff;
  font-weight: 600;
  padding-bottom: 1em;
}
.featuresSideMockup {
  position: absolute;
  right: 50vw;
  width: calc(50vw + 242px);
  top: 50%;
  transform: translateY(-50%);
}
.featuresTitle {
  font-size: 22px;
  color: #fff;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.featuresSubtitle {
  padding-top: 0.5rem !important;
  font-size: 17px;
  color: rgba(255, 255, 255, 0.85);
  line-height: 22px;
  padding-bottom: 1em;
}
.featureIcon {
  font-size: 16px;
  width: 26px;
  text-align: center;
  height: 26px;
  border-radius: 50%;
  background: #fff;
  color: #2255d0;
  line-height: 26px;
  margin-right: 1em;
}
.landingCard {
  background: linear-gradient(154.38deg, #4ec3ee 17.21%, #2d93cd 87.48%);
  margin: 1em;
  border-radius: 6px;
  color: #fff;
  text-align: center;
  padding: 1em;
}
.landingCard > h1,
.landingCard > p {
  margin: 0;
}
.LandingSectionCard {
  position: relative;
  margin: 2em auto 9em;
  background-size: cover;
  background-position: center center;
  border-radius: 15px;
  padding: 0;
  height: 350px;
}
.bgOverlayGradient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  border-radius: 15px;
  background: linear-gradient(106.29deg, rgba(43, 129, 199, 0.71) -7.04%, rgba(86, 204, 242, 0.72) 151.29%);
  padding: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.whiteBtn {
  background: #fff !important;
  border-radius: 6px !important;
  border: none !important;
  height: 40px !important;
  width: 250px !important;
  color: rgba(43, 129, 199) !important;
}
.text {
  font-size: 1.2rem;
  color: #333e63;
}
.glossaryDictHeading {
  padding: 0px;
  font-size: 24px;
  font-weight: 700;
  background-color: #ced4da;
  width: 45px;
  border-radius: 50px;
  padding-block: 4px;
  text-align: center;
  color: #fff;
  font-family: AzoLight, sans-serif;
}

.glossaryText {
  cursor: pointer;
  font-size: 1rem;
  color: #3f3d56;
}
.glossaryText:hover {
  color: #0c64ae;
}

.glossaryHeader {
  height: 300px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebf8f6;
}
.glossaryHeader > div > h1 {
  font-size: 72px;
  font-weight: 900;
  margin: 0;
  text-shadow: 0.1em 0.1em 0.4em rgba(28, 175, 154, 0.3);
}
.glossaryHeader > div > p {
  line-height: 1em;
  font-size: 22px;
  color: #60747e;
}
.Breadcrumb > ol {
  margin-top: 0;
  padding: 1em 0;
  background-color: #fff !important;
}
.Breadcrumb > ol > li > a {
  color: #005fcf;
}
.T2BannerHeading {
  display: flex;
  font-size: 3.25rem;
  color: #000;
  font-weight: 900;
}
.T2BannerSubHeading {
  display: flex;
  text-align: justify;
  font-size: 1.35rem;
  color: #60747e;
}
.T2BannerParagraph {
  font-family: 'AzoLight';
  font-size: 1.25rem;
  color: #708396;
  padding-right: 1em;
  text-align: justify;
}
.T2emailInput {
  padding: 1.5em 1em !important;
  border-radius: 6px 0 0 6px !important;
  border: 1px solid #ededed !important;
}
.T2bannerBtn {
  background: linear-gradient(28.37deg, #3f4a55 2.81%, #708396 160.42%);
  border-radius: 10px !important;
  border: none !important;
  min-height: 55px;
  padding: 0 2em;
  margin-top: 1em;
}
.T2SectionHeading {
  font-display: swap;
  display: flex;
  font-size: 28px;
  color: #000;
  font-weight: 600;
  margin-top: 0;
}
.T2SectionSubHeading {
  font-display: swap;
  display: flex;
  font-size: 20px;
  color: #525252;
  padding: 1em 0;
  text-align: justify;
}
.T2Card > h1,
.T2Card > p {
  text-align: center;
}
.T2Card {
  padding: 2em;
  margin: 1em;
  background: #fff;
  width: 100%;
  box-shadow: 0 6px 17px 0 rgba(174, 174, 186, 0.1), 0 8px 24px 0 rgba(174, 174, 186, 0.1);
  color: #525252;
  border-radius: 10px;
}
.T2Card > h1 {
  font-size: 60px;
}
.T2bgTestimonial {
  padding: 3em;
  color: #fff;
  border-radius: 1em;
  background: linear-gradient(106.29deg, rgba(43, 129, 199, 0.71) -7.04%, rgba(86, 204, 242, 0.72) 151.29%);
}
.T2testimonialContainerParent {
  margin: 5em auto;
}
.T2testimonialContainer {
  direction: ltr;
  width: 100%;
  padding: 1.5rem 0 0;
  text-align: center;
  background: #fff;
  border-radius: 1em;
}
.T2reviewerImg {
  width: 85px !important;
  border-radius: 10px;
  margin-top: 2em;
  border: 1px solid #ededed;
}
.T2reviewerName {
  font-size: 20px;
  color: #fff;
}
.T2LandingSectionCard {
  position: relative;
  margin: 2em auto 9em;
  border-radius: 15px;
  padding: 0;
  height: 350px;
}
.T2featuresTitle {
  font-size: 22px;
  color: #0c1a1e;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.T2featuresSubtitle {
  padding-top: 0.5rem !important;
  font-size: 17px;
  color: #373737;
  line-height: 22px;
  padding-bottom: 1em;
}
.T2bgOverlayGradient,
.T3bgOverlayGradient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  padding: 5em;
  display: flex;
  text-align: center;
}
.T2bgOverlayGradient {
  border-top: 2px solid #eee;
  align-items: center;
  justify-content: center;
}
.carousel .control-dots {
  margin: -5px 0 !important;
}
.T3BannerHeading {
  display: flex;
  font-size: 2.8rem;
  color: #0c64ae;
  font-weight: 600;
  margin-bottom: 0.65em;
}
.T3cardDesign {
  width: 18rem;
  margin-right: 1em;
  border: none !important;
  min-height: 200px;
}
.heading3,
.heading4 {
  font-display: swap;
  font-size: 2.35rem;
  color: #373737;
  font-weight: 700;
  font-family: AzoLight, sans-serif;
}
.T3cardDesign > div {
  padding-left: 0;
}
.T3bgOverlayGradient {
  border-radius: 15px;
  background: linear-gradient(106.73deg, #157dd5 -3.32%, #0c64ae 93.37%);
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 480px) {
  .heading4,
  .stepTxt {
    font-display: swap;
  }

  .contactSales,
  .dragSpan,
  .heading {
    font-size: 30px !important;
    font-weight: 700;
  }
  .heading3,
  .heading4 {
    margin-top: 60px;
    margin-left: 0 !important;
  }
  .directoryList {
    padding: 0;
  }
  .heading4 {
    font-size: 35px;
  }
  .pricingIcon {
    margin-left: 5px;
    height: 23px;
  }
  .cardCommon,
  .cardCommon1 {
    height: 80px !important;
  }
  .intLogo {
    height: 50px;
    margin-bottom: 0;
  }
  .intTitle {
    font-size: 10px;
    margin-bottom: 0;
  }
  .headingInt,
  .headingModules {
    font-size: 30px;
  }
  .step > div,
  .stepTxt {
    font-size: 12px;
  }
  .stepHome {
    margin-top: 15%;
  }
  .pricingHeadingContainer,
  .switch {
    justify-content: center;
    align-items: center;
  }
  .cardDesign {
    min-width: 100% !important;
  }
  .contactSales br,
  .employeeTitle br,
  .navbarContainer {
    display: none;
  }
  .dragSpan {
    justify-content: center;
    align-items: center;
    margin-top: -5px;
  }
  .monthlySwitch {
    justify-content: center;
    margin-top: 30px;
  }
  .sectionTitle {
    font-size: 45px;
    padding: 0;
    margin-top: -10px;
    justify-content: center;
    margin-bottom: 0;
  }
  .employeeTitle {
    font-size: 16px;
    padding: 0;
    margin: -30px 0 0;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1;
  }
  .modulesCardPadding {
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .decoraion {
    margin-left: 30px !important;
  }

  .employeeBox {
    align-items: center;
    border-color: #dedede;
    width: 200px;
    padding: 5px;
    margin: 10px;
  }
  .headerLogo {
    width: 50px;
  }
  .pricingBtn {
    margin-top: 20px;
    height: 40px;
    min-width: 100%;
  }
  .primaryBtn2 {
    height: 40px;
    margin-top: -10px;
  }
  .headingWeb {
    font-size: 27px;
    font-weight: 700;
    margin-left: 0;
  }
  .homeCard {
    font-size: 15px;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .moduleText {
    line-height: 20px;
    font-size: 16px;
    margin-left: 20px;
  }
  .pricingPlan,
  .pricingPlan2 {
    background-color: none;
    box-shadow: none;
  }
  .iconText {
    font-size: 11px;
  }
  .modulePrice {
    margin-left: 30px;
  }
  .contactSales {
    font-size: 28px;
    margin-bottom: 50px;
  }
  .integBtn2 {
    width: 90%;
    margin: 20px;
  }
  .clientsLogo {
    height: 40px !important;
  }
  .webImg {
    padding-top: 0;
    padding-bottom: 0;
  }
  .heading {
    margin-bottom: 15px;
  }
  .taglineText {
    font-size: 20px;
  }
}
.myDiv {
  column-count: 3;
  column-gap: '10px';
}
.liMy {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}
@media screen and (max-width: 900px) {
  .myDiv {
    column-count: 2;
    column-gap: '60px';
  }
}
@media screen and (max-width: 748px) {
  .myDiv {
    column-count: 1;
    column-gap: '60px';
  }
}
